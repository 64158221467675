<template>
  <div>
    <Iframe :src="user.timesheetsurl" v-show="true" />
  </div>
</template>

<script>
import Iframe from "@/components/Iframe";
import { mapState } from "vuex";

export default {
  components: {
    Iframe,
  },

  computed: {
    ...mapState(["user"]),
  },
};
</script>